import { useUnit } from 'effector-react';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import Button from 'components/Button';
import { InputWithSelect } from 'components/FormControls';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { IMarkup, IMarkups } from 'shared/api/markups';
import { markupSources } from 'shared/api/markups/markups';
import preventClick from 'shared/helpers/preventClick';
import { required } from 'shared/helpers/validations';
import { deletedMarkup } from './model';
import Styled from './styles';

interface MarkupDeleteModalProps {
  data: IMarkup;
  closeMarkupModal?: () => void;
  closeModal: () => void;
  withPopover?: boolean;
}

export const MarkupDeleteModal: React.FC<MarkupDeleteModalProps> = (props) => {
  const { closeMarkupModal, withPopover, data, closeModal } = props;
  const deleteMarkup = useUnit(deletedMarkup);

  const renderMarkup = (response: { data: IMarkups }) => {
    const filteredResults = response.data?.results.filter((i) => i.id !== data!.id);
    return filteredResults.map((i) => ({
      value: i.id,
      id: i.id,
      label: i.title,
    }));
  };

  const onSubmit = async (formData: { price_item: { id: number } }) => {
    try {
      if (!data?.has_events) {
        await markupSources.markupItemDelete(data!.id, null);
      } else {
        await markupSources.markupItemDelete(data.id, formData.price_item.id);
      }

      if (closeMarkupModal) {
        closeMarkupModal();
      }
      deleteMarkup();

      closeModal();
      NotificationManager.success('Успешно');
    } catch (error) {
      NotificationManager.error('Ошибка');
      if (closeMarkupModal) {
        closeMarkupModal();
      }

      closeModal();
    }
  };

  const modal = (
    <Styled.Container>
      <Styled.Top>
        <Styled.Question />
        Удалить наценку
      </Styled.Top>
      <Styled.Text>
        Наценка будет полностью удалена, после чего в мероприятиях установится выбранная наценка. Вы уверены,
        что хотите совершить это действие?
      </Styled.Text>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Styled.PriceItem>
              <Field name="price_item" validate={required}>
                {({ input, meta }) => (
                  <InputWithSelect
                    isAsync
                    route={markupSources}
                    optionsRenderer={renderMarkup}
                    {...input}
                    meta={meta}
                    searchQueryName="search_string"
                  />
                )}
              </Field>
            </Styled.PriceItem>

            <Styled.Footer>
              <Button type="button" transparent onClick={closeModal}>
                Отмена
              </Button>
              <Button danger>Удалить</Button>
            </Styled.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );

  return (
    <>
      {!data?.has_events ? (
        <>
        <Styled.GlobalStyles />
        <ConfirmModal
          withPopover={withPopover && withPopover}
          closeModal={closeModal}
          data={{
            title: 'Удалить наценку',
            text: 'Данная наценка удалится. Вы уверены, что хотите совершить это действие?',
            onSubmit,
          }}
        />
        </>
      ) : (
        <>
          {withPopover ? (
            <Styled.Popover onClick={preventClick} onMouseDown={preventClick}>
              <Styled.PopoverGlobalStyles />
              {modal}
            </Styled.Popover>
          ) : (
            <>
              <Styled.GlobalStyles />
              {modal}
            </>
          )}
        </>
      )}
    </>
  );
};
