import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import Button from 'components/Button';
import permissions from 'config/permissions';
import { selectAccount } from 'entities/account/reducer';
import { openModal, openRouterModal } from 'entities/modal';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import { editTranslationData } from 'shared/helpers/form';
import { getUserName } from 'shared/helpers/user';
import landingsSitesSources from 'shared/sources/landings/landingsSites';
import { ModalFunctions } from '../../../../../interfaces/modal';
import TrashIcon from '../../../../../static/icons/trash.svg';
import Can from '../../../Can';
import LandingsModalLayout from '../landingsModalLayout';
import withLandingData from '../withLandingData';
import SiteForm from './SiteForm';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  data?: any;
  modalState?: string;
  forceCloseModal: ModalFunctions['forceCloseModal'];
  openModal: ModalFunctions['openModal'];
  openRouterModal: ModalFunctions['openRouterModal'];
  onChangeLng: (lng: string) => any;
  showDeleteModal: () => void;
  updateList: () => void;
  onUpdate: (data: any) => any;
  create: (data: any) => any;
  update: (data: any) => any;
  lng: string;
}

const SiteModal: React.FC<ModalProps> = (props) => {
  const { data, onChangeLng, updateList, onUpdate, modalState, create, update, showDeleteModal, lng } = props;
  const account = useSelector(selectAccount);

  if (modalState === MODAL_STATES.CREATE) {
    return (
      <SiteForm
        key="create"
        title="Создание сайта"
        onSubmit={create}
        onSuccess={updateList}
        onChangeLng={onChangeLng}
        openRouterModal={openRouterModal}
        initialValues={{
          item_create: true,
          company_id: account.company.id,
        }}
      >
        <Button transparent type="button" onClick={props.closeModal}>
          Отмена
        </Button>
        <Button>Создать</Button>
      </SiteForm>
    );
  }

  const initialValues = {
    company_id: account.company.id,
    ...omit(data, ['company']),
    template_set: data.template_set && { label: data.template_set.name, value: data.template_set.id },
    content_groups: data.content_groups
      ? data.content_groups.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      : [],
    legal_information: data.legal_information
      ? {
          value: data.legal_information.id,
          label: data.legal_information.company_name,
        }
      : {},
    manager: !isEmpty(data.manager)
      ? {
          value: data.manager.id,
          label: getUserName(data.manager, true),
        }
      : {},
  };

  const handleUpdate = (formData) => {
    const dataForCompare = formData[lng];

    if (isEqual(dataForCompare, initialValues)) {
      return {};
    }

    return update(formData);
  };
  return (
    <SiteForm
      key="edit"
      title="Настройки сайта"
      isEdit
      onSubmit={handleUpdate}
      onSuccess={onUpdate}
      forceCloseModal={props.forceCloseModal}
      openModal={props.openModal}
      modalData={data}
      openRouterModal={props.openRouterModal}
      initialValues={initialValues}
      onChangeLng={onChangeLng}
    >
      <Can permission={permissions.companySuperManager}>
        <LandingsModalLayout.MobileContainer>
          <Button dangerTransparent onlyIcon type="button" onClick={showDeleteModal}>
            <TrashIcon />
          </Button>
        </LandingsModalLayout.MobileContainer>
        <LandingsModalLayout.TabletUpContainer>
          <Button dangerTransparent type="button" onClick={showDeleteModal}>
            Удалить
          </Button>
        </LandingsModalLayout.TabletUpContainer>
      </Can>
      <Button type="button" transparent onClick={props.closeModal}>
        Отмена
      </Button>
      <Button>Сохранить</Button>
    </SiteForm>
  );
};

SiteModal.defaultProps = {
  modalState: MODAL_STATES.EDIT,
};

const mapDispatchToProps = {
  openModal,
  openRouterModal,
};

const mappedSubmitData = (item) => ({
  ...item,
  ...(item.legal_information && {
    legal_information: item.legal_information.value,
  }),
  ...(item.template_set && {
    template_set: item.template_set.value,
  }),
  ...(item.currencies && {
    currencies: item.currencies.map((curr) => curr.code),
  }),
  reseller: item.reseller || '',
  domain: item.domain || null,
  manager_id: item.manager?.value || null,
  ...(item.content_groups && {
    content_groups: item.content_groups.map((group) => group.value),
  }),
});

export default withLandingData(connect(null, mapDispatchToProps)(SiteModal), {
  translation: 'common',
  newDetailApi: true,
  sources: landingsSitesSources,
  mappedCreateData: (submitData) =>
    editTranslationData(submitData, (item) => ({
      ...mappedSubmitData(item),
      ui_config_filter_type: 1,
    })),
  mappedUpdateData: (submitData) => editTranslationData(submitData, (item) => mappedSubmitData(item)),
});
