import flatten from 'lodash/flatten';
import get from 'lodash/get';
import head from 'lodash/head';
import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import { i18n } from 'next-i18next';
import { Input, Checkbox, RangeDateTimePicker } from 'components/FormControls';
import InputWithSelect, {
  OptionWithCheckbox,
  OptionWithEventInfo,
  OptionWithEventCategory,
  InputWithSelectAllMenuList,
} from 'components/FormControls/InputWithSelect';
import LandingsDateTimePickerContainer from 'containers/LandingsDateTimePickerContainer';
import { companySources } from 'entities/company';
import { currencySources } from 'entities/currency';
import { basicEventSources } from 'entities/events';
import { membersCompaniesSources } from 'entities/members/companies';
import { orderSources } from 'entities/order';
import { fetchTag, tagsRoute } from 'shared/api/landings/tags';
import { referencePersonsSources } from 'shared/api/reference/persons';
import { categoryRoutes } from '../api/reference/categories';
import countrySources from '../api/reference/country';
import locationSource from '../api/reference/locations';
import { referencePlaceSources } from '../api/reference/places';
import {
  renderMembers,
  renderEventsForMultiSelect,
  renderFilterPlaygrounds,
  renderSites,
  renderSources,
  renderLegalInfo,
  renderPayments,
  renderEmails,
  renderPhones,
  renderContentGroups,
  renderReferencePersons,
  renderLocations,
  renderCountries,
  renderReferencePlaces,
  renderCategories,
  renderActiveEventsForMultiSelect,
  renderBasicEventsForMultiSelect,
} from '../helpers/optionsRenderers';
import playgroundsSources from '../sources/playgrounds';
import subdomains from '../sources/subdomains';
import { ANNOUNCEMENT_STATUS_DEFAULT_SELECT, ANNOUNCEMENT_STATUS_SELECT_IN } from './ANNOUNCEMENT_STATUS';
import { ANNOUNCEMENT_TYPES_OPTIONS } from './ANNOUNCEMENT_TYPES';
import FRAGMENT_TYPES from './FRAGMENT_TYPES';
import { INVOICE_STATUSES, INVOICE_STATUS_SELECT_IN } from './INVOICES';
import { LANGUAGES } from './LANGUAGES';
import { STATUSES_SELECT } from './ORDER';
import { PARSERS_STATUS_OPTIONS } from './PARSERS_STATUS';
// eslint-disable-next-line
import InputWithSelectMultiValueForEvent from 'components/FormControls/InputWithSelect/InputWithSelectMultiValueForEvent';

const filters = {
  search: {
    component: Input,
    props: {
      label: 'Мероприятие',
    },
  },
  name: {
    component: Input,
    props: {
      label: 'Имя',
    },
  },
  email: {
    component: Input,
    props: {
      label: 'Email',
    },
  },
  phoneStr: {
    component: Input,
    query: 'phone',
    props: {
      label: 'Телефон',
    },
  },
  plainSearch: {
    component: Input,
    query: 'search',
    props: {
      label: 'Поиск',
    },
  },
  legal__in: {
    component: InputWithSelect,
    detailRoute: 'legalInfoDetail',
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Юр лицо',
      route: companySources,
      routeName: 'legalInfo',
      optionsRenderer: renderLegalInfo,
    },
  },
  phone: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      withoutPagination: true,
      label: 'Телефон',
      route: companySources,
      routeName: 'landingPhones',
      optionsRenderer: renderPhones,
    },
  },
  acquiring__in: {
    component: InputWithSelect,
    detailRoute: 'paymentsDetail',
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Эквайринг',
      route: companySources,
      routeName: 'payments',
      optionsRenderer: renderPayments,
    },
  },
  email__in: {
    component: InputWithSelect,
    detailRoute: 'emailConfigDetail',
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Email',
      route: companySources,
      routeName: 'emailConfig',
      optionsRenderer: renderEmails,
    },
  },
  event_category__in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Категория',
      route: categoryRoutes,
      searchQueryName: 'search_string',
      query: { language_code: LANGUAGES.RU },
      optionsRenderer: renderCategories,
      routeName: 'root',
    },
  },
  event_parent_category__in: {
    component: InputWithSelect,
    detailRoute: 'categoryTypeDetail',
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Родительская категория',
      route: categoryRoutes,

      searchQueryName: 'search_string',
      query: { language_code: LANGUAGES.RU },
      optionsRenderer: renderCategories,
      routeName: 'categoryTypes',
    },
  },
  category_id_in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Категория',
      route: categoryRoutes,
      searchQueryName: 'search_string',
      query: { language_code: LANGUAGES.RU },
      optionsRenderer: renderCategories,
      routeName: 'root',
    },
  },
  event_place__in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Площадка',
      route: referencePlaceSources,
      query: { language_code: LANGUAGES.RU },
      optionsRenderer: renderReferencePlaces,
      searchQueryName: 'search_string',
    },
  },
  event_location__in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Город мероприятия',
      route: locationSource,
      searchQueryName: 'search_string',
      optionsRenderer: renderLocations,
      query: { language_code: LANGUAGES.RU },
    },
  },
  location_id_in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Город',
      route: locationSource,
      searchQueryName: 'search_string',
      optionsRenderer: renderLocations,
      query: { language_code: LANGUAGES.RU },
    },
  },
  place: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      label: 'Площадка',
      route: referencePlaceSources,
      query: { language_code: LANGUAGES.RU },
      optionsRenderer: renderReferencePlaces,
      searchQueryName: 'search_string',
    },
  },
  country: {
    props: {
      isAsync: true,
      route: countrySources,
      optionsRenderer: renderCountries,
      searchQueryName: 'search_string',
    },
  },
  place__in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Площадка',
      route: referencePlaceSources,
      query: { language_code: LANGUAGES.RU },
      optionsRenderer: renderReferencePlaces,
      searchQueryName: 'search_string',
    },
  },

  place_id_in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Площадка',
      route: referencePlaceSources,
      query: { language_code: LANGUAGES.RU },
      optionsRenderer: renderReferencePlaces,
      searchQueryName: 'search_string',
    },
  },
  currency__in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Валюта',
      route: currencySources,
    },
  },
  site__in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Сайт',
      routeName: 'filtersSites',
      route: orderSources,
      optionsRenderer: renderSites,
    },
  },
  customer__in: {
    component: InputWithSelect,
    detailRoute: 'filtersBroker',
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Брокер покупатель',
      routeName: 'filtersBrokers',
      route: orderSources,
      query: {
        my_sales: true,
      },
    },
  },
  seller__in: {
    component: InputWithSelect,
    detailRoute: 'filtersBroker',
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Брокер продавец',
      routeName: 'filtersBrokers',
      route: orderSources,
      query: {
        my_sales: false,
      },
    },
  },
  sector__in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      withoutPagination: true,
      defaultOptions: false,
      label: 'Сектор',
      route: playgroundsSources,
      optionsRenderer: renderFilterPlaygrounds,
      query: {
        fragment_type: FRAGMENT_TYPES.SECTOR,
      },
    },
  },
  category__in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      withoutPagination: true,
      defaultOptions: false,
      label: 'Категория',
      route: playgroundsSources,
      optionsRenderer: renderFilterPlaygrounds,
      query: {
        fragment_type: FRAGMENT_TYPES.CATEGORY,
      },
    },
  },
  price__gte: {
    isRange: true,
    props: {
      sign: '₽',
      from: {
        name: 'price__gte',
        label: 'Цена Мин',
      },
      to: {
        name: 'price__lte',
        label: 'Цена Макс',
      },
    },
  },
  price__lte: {
    isRange: true,
    props: {
      sign: '₽',
      from: {
        name: 'price__gte',
        label: 'Цена Мин',
      },
      to: {
        name: 'price__lte',
        label: 'Цена Макс',
      },
    },
  },
  total_price__gte: {
    isRange: true,
    props: {
      sign: '₽',
      from: {
        name: 'total_price__gte',
        label: 'Итог цена Мин',
      },
      to: {
        name: 'total_price__lte',
        label: 'Итог цена Макс',
      },
    },
  },
  total_price__lte: {
    isRange: true,
    props: {
      sign: '₽',
      from: {
        name: 'total_price__gte',
        label: 'Итог цена Мин',
      },
      to: {
        name: 'total_price__lte',
        label: 'Итог цена Макс',
      },
    },
  },
  orderStatus: {
    component: InputWithSelect,
    query: 'status__in',
    props: {
      defaultValue: [
        { value: 1, label: 'Не в работе' },
        { value: 3, label: 'Доставка' },
        { value: 5, label: 'Бронь' },
        { value: 9, label: 'Время' },
        { value: 10, label: 'В работе' },
      ],
      isMulti: true,
      label: 'Статус',
      withCounter: true,
      isClearable: false,
      withExceptOption: true,
      closeMenuOnSelect: false,
      isSearchable: false,
      options: STATUSES_SELECT,
      hideSelectedOptions: false,
      controlShouldRenderValue: false,
      clearValueIfEqualToDefault: true,
      components: { Option: OptionWithCheckbox, MenuList: InputWithSelectAllMenuList },
    },
  },
  parsersStatus: {
    component: InputWithSelect,
    query: 'status',
    props: {
      isMulti: true,
      label: 'Статус',
      withCounter: true,
      isClearable: false,
      withExceptOption: true,
      closeMenuOnSelect: false,
      isSearchable: false,
      options: PARSERS_STATUS_OPTIONS,
      hideSelectedOptions: false,
      controlShouldRenderValue: false,
      clearValueIfEqualToDefault: true,
      components: { Option: OptionWithCheckbox },
    },
  },
  announcementStatus: {
    component: InputWithSelect,
    query: 'status__in',
    props: {
      defaultValue: ANNOUNCEMENT_STATUS_DEFAULT_SELECT,
      isMulti: true,
      label: 'Статус',
      withCounter: true,
      withExceptOption: true,
      closeMenuOnSelect: false,
      options: ANNOUNCEMENT_STATUS_SELECT_IN,
      hideSelectedOptions: false,
      controlShouldRenderValue: false,
      components: { Option: OptionWithCheckbox },
    },
  },
  announcementType: {
    component: InputWithSelect,
    query: 'sale',
    props: {
      isMulti: false,
      label: 'Тип билета',
      isSearchable: false,
      options: ANNOUNCEMENT_TYPES_OPTIONS,
    },
  },
  announcementProvider: {
    component: InputWithSelect,
    query: 'provider',
    props: {
      isAsync: true,
      isMulti: false,
      label: 'Поставщик',
      route: companySources,
      routeName: 'providers',
    },
    detailRoute: 'providersDetail',
  },
  invoiceStatus: {
    component: InputWithSelect,
    query: 'status__in',
    props: {
      defaultValue: [
        { value: INVOICE_STATUSES.NEW, label: 'Не в работе', id: INVOICE_STATUSES.NEW },
        { value: INVOICE_STATUSES.WAIT, label: 'Ожидание', id: INVOICE_STATUSES.WAIT },
        { value: INVOICE_STATUSES.IN_WORK, label: 'В работе', id: INVOICE_STATUSES.IN_WORK },
        { value: INVOICE_STATUSES.IN_ORDER, label: 'В заказе', id: INVOICE_STATUSES.IN_ORDER },
        { value: INVOICE_STATUSES.CANCEL, label: 'Отмена', id: INVOICE_STATUSES.CANCEL },
      ],
      isMulti: true,
      label: 'Статус',
      withCounter: true,
      withExceptOption: true,
      closeMenuOnSelect: false,
      options: INVOICE_STATUS_SELECT_IN,
      hideSelectedOptions: false,
      controlShouldRenderValue: false,
      components: { Option: OptionWithCheckbox },
    },
  },
  complete_orders: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Показывать завершенные',
      type: 'round',
      rtl: true,
    },
  },
  refund_orders: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Показывать возвращённые',
      type: 'round',
      rtl: true,
    },
  },
  refund_request: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Запрос на возврат',
      type: 'round',
      rtl: true,
    },
  },
  reject_orders: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Показывать отменённые',
      type: 'round',
      rtl: true,
    },
  },
  burn_orders: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Показывать сгоревшие',
      type: 'round',
      rtl: true,
    },
  },
  outdated: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Показывать прошедшие',
      type: 'round',
      rtl: true,
    },
  },
  date_type: {
    component: InputWithSelect,
    props: {
      defaultValue: [{ value: 'active', label: 'Активные' }],
      isMulti: false,
      label: 'Тип даты мероприятия',
      isClearable: false,
      isSearchable: false,
      options: [
        { value: 'active', label: 'Активные' },
        { value: 'past', label: 'Прошедшие' },
        { value: 'all', label: 'Все' },
      ],
    },
  },
  ungroup_events: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Разгруппировать мероприятия',
      type: 'round',
      rtl: true,
    },
  },
  disallow_indexing: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Запрещена индексация',
      type: 'round',
      rtl: true,
    },
  },
  in_stock: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Билеты в наличии',
      type: 'round',
      rtl: true,
    },
  },
  is_filled: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Заполненные',
      type: 'round',
      rtl: true,
    },
  },
  is_not_filled: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Не заполненные',
      type: 'round',
      rtl: true,
    },
  },
  manager__isnull: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Без менеджера',
      type: 'round',
      rtl: true,
    },
  },
  fill_by_template: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Заполняемое по шаблону',
      type: 'round',
      rtl: true,
    },
  },
  hide_foreign: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Скрыть чужие билеты',
      type: 'round',
      rtl: true,
    },
  },
  hide_our: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Скрыть свои билеты',
      type: 'round',
      rtl: true,
    },
  },
  manager__in: {
    component: InputWithSelect,
    detailRoute: 'membersDetail',
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Координатор',
      route: companySources,
      routeName: 'managersAssign',
      withCounter: true,
      closeMenuOnSelect: false,
      hideSelectedOptions: false,
      optionsRenderer: renderMembers,
      controlShouldRenderValue: false,
      components: { Option: OptionWithCheckbox },
    },
  },
  manager_id_in: {
    component: InputWithSelect,
    detailRoute: 'membersDetail',
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Менеджер',
      route: companySources,
      routeName: 'managersAssign',
      withCounter: true,
      closeMenuOnSelect: false,
      hideSelectedOptions: false,
      optionsRenderer: renderMembers,
      controlShouldRenderValue: false,
      components: { Option: OptionWithCheckbox },
    },
  },
  event__in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      isEventFilter: true,
      label: 'Мероприятие',
      route: basicEventSources,
      closeMenuOnSelect: false,
      components: {
        Option: OptionWithEventInfo,
        MultiValue: InputWithSelectMultiValueForEvent,
      },
      optionsRenderer: renderEventsForMultiSelect,
      query: {
        language_code: i18n?.language || LANGUAGES.RU,
      },
      searchQueryName: 'search_string',
    },
  },
  all_event__in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      isEventFilter: true,
      label: 'Все мероприятия',
      route: basicEventSources,
      closeMenuOnSelect: false,
      components: {
        Option: OptionWithEventInfo,
        MultiValue: InputWithSelectMultiValueForEvent,
      },
      optionsRenderer: renderEventsForMultiSelect,
      query: {
        language_code: i18n?.language || LANGUAGES.RU,
        date_type: 'all',
      },
      searchQueryName: 'search_string',
    },
  },
  active_event__in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      isEventFilter: true,
      showOnlyActiveEvents: true,
      label: 'Актуальные мероприятия',
      route: basicEventSources,
      closeMenuOnSelect: false,
      components: {
        Option: OptionWithEventInfo,
        MultiValue: InputWithSelectMultiValueForEvent,
      },
      optionsRenderer: renderActiveEventsForMultiSelect,
      query: {
        language_code: i18n?.language || LANGUAGES.RU,
        date_type: 'active',
      },
      searchQueryName: 'search_string',
    },
  },
  is_top: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Топ афиша',
      type: 'round',
      rtl: true,
    },
  },
  is_cover: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'На обложке',
      type: 'round',
      rtl: true,
    },
  },
  order_created: {
    component: RangeDateTimePicker,
    props: {
      isRangeDateTimePicker: true,
      placeholder: ['Дата создания заказа', ''],
    },
  },
  created: {
    component: RangeDateTimePicker,
    props: {
      isRangeDateTimePicker: true,
      isShortUnderline: true,
      placeholder: ['Дата создания заявки', ''],
    },
  },
  event_date: {
    component: RangeDateTimePicker,
    props: {
      isRangeDateTimePicker: true,
      placeholder: ['Дата мероприятия', ''],
    },
  },
  order_date: {
    component: RangeDateTimePicker,
    props: {
      isRangeDateTimePicker: true,
      placeholder: ['Выбрать дату', ''],
    },
  },
  table_event_date: {
    component: RangeDateTimePicker,
    props: {
      isRangeDateTimePicker: true,
      placeholder: ['Выбрать дату', ''],
    },
  },
  orders_graph_graph_order_date: {
    component: RangeDateTimePicker,
    props: {
      isRangeDateTimePicker: true,
      placeholder: ['Выбрать дату', ''],
    },
  },
  refund_graph_order_date: {
    component: RangeDateTimePicker,
    props: {
      isRangeDateTimePicker: true,
      placeholder: ['Выбрать дату', ''],
    },
  },
  sales_graph_order_date: {
    component: RangeDateTimePicker,
    props: {
      isRangeDateTimePicker: true,
      placeholder: ['Выбрать дату', ''],
    },
  },
  reject_graph_order_date: {
    component: RangeDateTimePicker,
    props: {
      isRangeDateTimePicker: true,
      placeholder: ['Выбрать дату', ''],
    },
  },
  date: {
    component: RangeDateTimePicker,
    props: {
      withoutOutdated: true,
      isRangeDateTimePicker: true,
      placeholder: ['Выбрать дату', ''],
    },
  },
  date_edit: {
    component: RangeDateTimePicker,
    props: {
      isRangeDateTimePicker: true,
      placeholder: ['Выбрать дату', ''],
    },
  },
  source: {
    component: InputWithSelect,
    detailRoute: 'sourceDetailPrefix',
    props: {
      label: 'Источник',
      isAsync: true,
      isMulti: true,
      route: membersCompaniesSources,
      routeName: 'source',
      optionsRenderer: renderSources,
    },
  },
  external_id: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      isEventFilter: true,
      label: 'Мероприятие',
      route: basicEventSources,
      searchQueryName: 'search_string',
      closeMenuOnSelect: false,
      components: {
        Option: OptionWithEventInfo,
        MultiValue: InputWithSelectMultiValueForEvent,
      },
      query: {
        language_code: i18n?.language || LANGUAGES.RU,
      },
      optionsRenderer: renderEventsForMultiSelect,
    },
  },
  venue: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Эталонная площадка',
      route: referencePlaceSources,
      query: { language_code: LANGUAGES.RU },
      optionsRenderer: renderReferencePlaces,
      searchQueryName: 'search_string',
    },
  },
  reference_category: {
    component: InputWithSelect,
    query: 'category',
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Эталонная категория',
      route: categoryRoutes,
      searchQueryName: 'search_string',
      query: { language_code: LANGUAGES.RU },
      optionsRenderer: renderCategories,
      routeName: 'root',
    },
  },
  sub_domain__in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Город',
      route: subdomains,
    },
  },
  currency: {
    component: null,
    props: {
      isAsync: true,
      route: currencySources,
    },
  },
  // new landings
  indexing_prohibited: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Запрещена индексация',
      type: 'round',
      rtl: true,
    },
  },
  show_past: {
    component: Checkbox,
    fieldType: 'checkbox',
    props: {
      children: 'Показывать прошедшие',
      type: 'round',
      rtl: true,
    },
  },
  landings_places: {
    component: InputWithSelect,
    query: 'places',
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Площадка',
      route: referencePlaceSources,
      query: { language_code: LANGUAGES.RU },
      optionsRenderer: renderReferencePlaces,
      searchQueryName: 'search_string',
    },
  },
  event_categories: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Категория',
      route: categoryRoutes,
      routeName: 'search',
      components: { Option: OptionWithEventCategory },
    },
  },
  search_string: {
    component: Input,
    query: 'search_string',
    props: {
      label: 'Поиск',
    },
  },
  content_groups: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      searchQueryName: 'search_string',
      label: 'Тег',
      optionsRenderer: renderContentGroups,
      route: { root: tagsRoute, detail: fetchTag },
    },
  },
  content_group_id_in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      searchQueryName: 'search_string',
      label: 'Тег',
      optionsRenderer: renderContentGroups,
      route: { root: tagsRoute, detail: fetchTag },
    },
  },

  related_content_group_id_in: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      searchQueryName: 'search_string',
      label: 'Тег',
      optionsRenderer: renderContentGroups,
      route: { root: tagsRoute, detail: fetchTag },
    },
  },
  landings_tags: {
    component: InputWithSelect,
    query: 'tags',
    props: {
      isAsync: true,
      isMulti: true,
      searchQueryName: 'search_string',
      label: 'Тег',
      optionsRenderer: renderContentGroups,
      route: { root: tagsRoute, detail: fetchTag },
    },
  },
  landings_date: {
    component: LandingsDateTimePickerContainer,
    props: {
      isLandingsRangeDateTimePicker: true,
      placeholder: ['Выбрать дату', ''],
    },
  },
  persons: {
    component: InputWithSelect,
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Персоналии',
      route: referencePersonsSources,
      query: {
        language_code: i18n?.language || LANGUAGES.RU,
      },
      searchQueryName: 'search_string',
      optionsRenderer: renderReferencePersons,
    },
  },
  started: {
    component: RangeDateTimePicker,
    props: {
      isRangeDateTimePicker: true,
      isShortUnderline: true,
      useStubTime: true,
      placeholder: ['Выбрать дату', ''],
    },
  },
  market_search_string: {
    component: Input,
    query: 'search_string',
    props: {
      label: 'Мероприятие на маркете',
    },
  },
  base_event_id_in: {
    component: InputWithSelect,
    query: 'event_id_in',
    props: {
      isAsync: true,
      isMulti: true,
      label: 'Базовое мероприятие',
      route: basicEventSources,
      closeMenuOnSelect: false,
      searchQueryName: 'search_string',
      components: {
        Option: OptionWithEventInfo,
      },
      optionsRenderer: renderBasicEventsForMultiSelect,
      query: {
        language_code: i18n?.language || LANGUAGES.RU,
      },
    },
  },
};

const getFilterQueries = (filterNames: string[], config: any) => {
  const names = filterNames.map((item) => {
    if (get(config, `${item}.query`)) {
      return config[item].query;
    }

    if (get(config, `${item}.props.isRangeDateTimePicker`)) {
      const underline = get(config, `${item}.props.isShortUnderline`) ? '_' : '__';

      return [`${item}${underline}gte`, `${item}${underline}lte`];
    }

    if (get(config, `${item}.props.isLandingsRangeDateTimePicker`)) {
      return ['date_gte', 'date_lte'];
    }

    return item;
  });

  return flatten(names);
};

const getFilterLabels = () => {
  const filtersData = reduce(
    filters,
    (acc, curr: any, index) => {
      if (curr.props.label) {
        acc[index] = curr.props.label;
      }

      if (curr.props.children) {
        acc[index] = curr.props.children;
      }

      if (curr.props.placeholder) {
        if (isArray(curr.props.placeholder)) {
          acc[index] = head(curr.props.placeholder);
        } else {
          acc[index] = curr.props.placeholder;
        }
      }

      return acc;
    },
    {},
  );

  return filtersData;
};

export { getFilterQueries, getFilterLabels };

export default filters;
